<template>
  <SideBar @toggle="$emit('toggle')" class="is-large" ref="sideBar">
    <DoodleGeneralForm :title="formTitle"
                       in-progress-message="Updating user, Please wait..."
                       success-message="User Added Successfully"
                       :action-text="actionText"
                       :form="doodleForm"
                       :inProgress="doodleForm.inProgress" :completed="doodleForm.completed"
                       :errorMessageText="doodleForm.errorMessage"
                       @action="addEditUser"
                       :hide-action="hideActionButton"
                       @close="$refs.sideBar.emitToggle()">
      <div>
        <DoodleInput field-name="email" label-text="Email" type="text"
                     leftIcon="envelope"
                     v-model="doodleForm.email" :model="doodleForm.email"
                     rule="required|email|max:255" place-holder="student@gmail.com" :disabled="!isAdd"/>
        <DoodleInput field-name="firstName" label-text="First Name" type="text"
                     left-icon="user-tie"
                     rule="required|min:2|max:255" :model="doodleForm.firstName" :disabled="isView"
                     v-model="doodleForm.firstName" place-holder="" reff="newPassword"/>
        <DoodleInput field-name="lastName" label-text="Last Name" type="text"
                     left-icon="user-tie"
                     rule="required|min:2|max:255" :model="doodleForm.lastName" :disabled="isView"
                     v-model="doodleForm.lastName" place-holder=""/>
        <div v-show="!doodleForm.isAccountOwner && availableRoles.length>0 && !isView">
          <label class="label" for='roles'>Role(s)</label>
          <div class="field is-narrow">
            <div class="select has-text-weight-bold user-roles-select-div">
              <select v-model="roleSelected" id="roles">
                <option v-for="role in availableRoles" v-bind:value="role" v-bind:key="role.name">{{role.name}}</option>
              </select>
            </div>&nbsp;
            <button type="button" class="button is-info" @click="addUserRole">Add Role</button>
          </div>
        </div>
        <nav class="panel selected-user-role-panel" v-bind:class="{ 'is-danger' : 'roleError'}" v-show="!doodleForm.isAccountOwner">
          <p class="panel-heading"><span v-show="!isView">Selected</span> Roles</p>
          <div class="panel-block">
            <ul class="guru-selected-role-list">
              <li v-for="role in doodleForm.roles" v-bind:key="role.name">
                <a href="javascript:" v-on:click="removeUserRole(role)" v-show="role.name!='Account Owner' && !isView">
                  <font-awesome-icon icon="times-circle"/>
                </a>&nbsp;
                {{role.name}}
              </li>
            </ul>
          </div>
        </nav>
        <div class="has-text-danger" v-if="roleError">Atleast One Role should be added</div>
        <DoodleInput field-name="status" label-text="Status" type="text"
                     :model="doodleForm.status" disabled="true"
                     v-model="doodleForm.status" v-if="!isAdd"/>
      </div>
      <div class="has-text-centered user-delete-link" v-if="showDeleteUserLink">
        <a class="link has-text-danger" @click="showDeleteConfirm=true">Delete User</a>
      </div>
      <div class="has-text-centered user-delete-link" v-if="showDeactivate">
        <a class="link has-text-danger" @click="showChangeActivationConfirm=true"><span v-if="isUserActive">Deactivate</span><span v-if="isUserInActive">Activate</span></a>
      </div>
      <ConfirmationBox @action="deleteUser" confirmation-text="Are you sure, Do you want to delete this user?" yes-text="Yes"
                       no-text="No" @close="showDeleteConfirm=false" :show-confirmation="showDeleteConfirm"/>
      <ConfirmationBox @action="changeActivation" :confirmation-text="changeActivationConfirmText" yes-text="Yes"
                       no-text="No" @close="showChangeActivationConfirm=false" :show-confirmation="showChangeActivationConfirm"/>
      <template v-slot:successMessage>
        <div class="has-text-centered">
          <div class="register-thanks" v-if="isAdd">
            User added!
          </div>
          <div class="register-thanks" v-if="isEdit">
            User Updated!
          </div>
          <div class="register-thanks" v-if="isView">
            <span v-if="isView && !isUserAlreadyAcceptedInvite">User Deleted!</span>
            <span v-else-if="isUserActive">User Deactivated</span>
            <span v-else-if="isUserInActive">User Activated</span>
          </div>
        </div>
      </template>
    </DoodleGeneralForm>
    <div class="guru-hints">
      <div class="has-text-weight-bold is-underlined">Hints: </div> Users should login to JDoodle.com and accept your invite before you can include them into Assignments.
      Please note JDoodle.com does not send any email invites to users currently, you have to inform the users yourself.
    </div>
    <div class="guru-hints margin-top-10px">
      <div class="has-text-weight-bold is-underlined">Hints: User Permissions</div>
      <li>Account Owner has all permissions in the institute. Account owner can access all the Assignments and Students Groups in the institute.</li>
      <li>Institute Admins can create/edit all types of users. Institute Admin can manage Student Groups they created or they are included. Institute Admins can't create or manage Assignments</li>
      <li>Teachers can create/edit students, but not Admins/Teachers. Teachers can create and mange Student Groups and Assignments (both they created or included).</li>
    </div>
  </SideBar>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import DoodleInput from '../Utils/DoodleInput'
import DoodleGeneralForm from '../Utils/DoodleGeneralForm'
import SideBar from '../Utils/SideBar'
import { eventBus } from './../../assets/javascript/event-bus'
import ConfirmationBox from '../Utils/ConfirmationBox'
import { GURU_EVENTS, GURU_FORM_STATUS, GURU_USER_STATUS } from '../../assets/javascript/constants'

export default {
  name: 'guruUser',
  mixins: [formMixin],
  inject: ['$validator'],
  components: { ConfirmationBox, SideBar, DoodleGeneralForm, DoodleInput },
  props: ['mode', 'instituteCode', 'email', 'isAccountOwner', 'isInstituteAdmin', 'isTeacher'],
  data: function () {
    return {
      doodleForm: {
        email: null,
        firstName: null,
        lastName: null,
        roles: [],
        isAccountOwner: false,
        status: null
      },
      editMode: false,
      staticAvailableRoles: [
        { name: 'Institute Admin', index: 0 },
        { name: 'Teacher', index: 1 },
        { name: 'Student', index: 2 }
      ],
      availableRoles: [],
      roleSelected: null,
      showDeleteConfirm: false,
      showChangeActivationConfirm: false,
      roleError: false
    }
  },
  mounted () {
    if (this.localMode === 'view') {
      this.getUser()
    }

    if (this.isInstituteAdmin || this.isAccountOwner) {
      this.availableRoles = window._.cloneDeep(this.staticAvailableRoles)
    } else if (this.isTeacher) {
      let rls = []
      rls.push(window._.clone(this.staticAvailableRoles[2]))
      this.availableRoles = (rls)
    }
    this.roleSelected = (this.availableRoles && this.availableRoles.length > 0) ? this.availableRoles[0] : []
  },
  methods: {
    addEditUser () {
      if (this.isView) {
        for (let rl in this.doodleForm.roles) {
          this.availableRoles.splice(window._.findIndex(this.availableRoles, { name: this.doodleForm.roles[rl].name }), 1)
        }

        if (this.availableRoles.length > 0) {
          this.roleSelected = this.availableRoles[0]
        }

        this.editMode = true
        return
      }

      if (this.doodleForm.roles.length < 1) {
        this.roleError = true
        return
      }

      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/addUser',
        data: {
          instituteCode: this.instituteCode,
          emailId: this.doodleForm.email,
          firstName: this.doodleForm.firstName,
          lastName: this.doodleForm.lastName,
          roles: JSON.stringify(this.doodleForm.roles),
          mode: this.localMode
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          if (this.isAdd) {
            eventBus.$emit(GURU_EVENTS.ADD_TO_USERS_LIST, data.user)
          } else {
            eventBus.$emit(GURU_EVENTS.REPLACE_IN_USERS_LIST, data.user)
          }
        },
        jdaEvent: (this.isAdd) ? 'guru-add-user' : 'guru-update-user',
        jdaLabel: ''
      })
    },
    getUser () {
      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/getUser',
        data: {
          instituteCode: this.instituteCode,
          emailId: this.email
        },
        method: 'post',
        markCompleted: false,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          Object.assign(this.doodleForm, data.user)
        },
        jdaEvent: 'guru-get-user',
        jdaLabel: ''
      })
    },
    addUserRole () {
      let selectedRole = this.roleSelected
      this.doodleForm.roles.push(selectedRole)
      this.doodleForm.roles = window._.sortBy(this.doodleForm.roles, ['index'])

      this.availableRoles.splice(this.availableRoles.indexOf(selectedRole), 1)
      if (this.availableRoles.length > 0) {
        this.roleSelected = this.availableRoles[0]
      }
      this.roleError = false
    },
    removeUserRole (role) {
      this.availableRoles.push(role)
      this.availableRoles = window._.sortBy(this.availableRoles, ['index'])
      if (this.availableRoles.length > 0) {
        this.roleSelected = this.availableRoles[0]
      }

      this.doodleForm.roles.splice(this.doodleForm.roles.indexOf(role), 1)
    },
    deleteUser () {
      if (this.isUserAlreadyAcceptedInvite) {
        return
      }

      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/deleteUser',
        data: {
          instituteCode: this.instituteCode,
          emailId: this.email
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: () => {
          eventBus.$emit(GURU_EVENTS.REMOVE_FROM_USERS_LIST, this.email)
        },
        jdaEvent: 'guru-delete-user',
        jdaLabel: ''
      })
    },
    changeActivation () {
      if (!this.isUserAlreadyAcceptedInvite) {
        return
      }

      let activationType

      if (this.isUserActive) {
        activationType = 'deactivate'
      } else if (this.isUserInActive) {
        activationType = 'activate'
      } else {
        return
      }

      this.doodleForm.errorMessage = ''
      this.executeAPI({
        url: '/api/guru-api/changeActivation',
        data: {
          instituteCode: this.instituteCode,
          emailId: this.email,
          mode: activationType
        },
        method: 'post',
        markCompleted: true,
        form: this.doodleForm,
        jdaCategory: window.jda.CATEGORY.GURU,
        successAction: (data) => {
          eventBus.$emit(GURU_EVENTS.REPLACE_IN_USERS_LIST, data.user)
        },
        jdaEvent: (this.isUserActive) ? 'guru-deactivate-user' : 'guru-activate-user',
        jdaLabel: ''
      })
    }
  },
  computed: {
    formTitle () {
      let titlePrefix = ''

      if (this.localMode === GURU_FORM_STATUS.ADD) {
        titlePrefix = 'Add'
      } else if (this.localMode === GURU_FORM_STATUS.EDIT) {
        titlePrefix = 'Update'
      }

      return titlePrefix + ' User'
    },

    actionText () {
      let titlePrefix = 'Edit'

      if (this.localMode === GURU_FORM_STATUS.ADD) {
        titlePrefix = 'Add'
      } else if (this.localMode === GURU_FORM_STATUS.EDIT) {
        titlePrefix = 'Update'
      }

      return titlePrefix + ' User'
    },

    localMode () {
      if (this.editMode) {
        return GURU_FORM_STATUS.EDIT
      }

      return this.mode
    },

    isAdd () {
      return this.localMode === GURU_FORM_STATUS.ADD
    },
    isEdit () {
      return this.localMode === GURU_FORM_STATUS.EDIT
    },
    isView () {
      return this.localMode === GURU_FORM_STATUS.VIEW
    },
    isUserAlreadyAcceptedInvite () {
      return this.doodleForm.status !== GURU_USER_STATUS.PENDING_USER_ACCEPTANCE && this.doodleForm.status !== GURU_USER_STATUS.REJECT
    },
    isUserActive () {
      return this.doodleForm.status === GURU_USER_STATUS.ACTIVE
    },
    isUserInActive () {
      return this.doodleForm.status === GURU_USER_STATUS.IN_ACTIVE
    },
    changeActivationConfirmText () {
      return 'Are you sure, Do you want to ' + ((this.isUserActive) ? 'deactivate' : 'activate') + ' this user?'
    },
    hasTeacherRole () {
      return this.doodleForm.roles.findIndex((o) => { return o.name === this.staticAvailableRoles[1].name }) >= 0
    },
    hasInstituteAdminRole () {
      return this.doodleForm.roles.findIndex((o) => { return o.name === this.staticAvailableRoles[0].name }) >= 0
    },
    showDeactivate () {
      return (this.isUserActive || this.isUserInActive) &&
        (this.isAccountOwner || this.isInstituteAdmin || (this.isTeacher && !this.hasInstituteAdminRole && !this.hasTeacherRole))
    },
    showDeleteUserLink () {
      return (this.isView && !this.isUserAlreadyAcceptedInvite && (this.isAccountOwner || this.isInstituteAdmin || (this.isTeacher && !this.hasInstituteAdminRole && !this.hasTeacherRole)))
    },
    hideActionButton () {
      return (this.isView && (this.isTeacher && (this.hasInstituteAdminRole || this.hasTeacherRole)))
    }
  }
}
</script>

<style scoped lang="scss">
  .guru-registration-info {
    margin-bottom: 10px;
    color: green;
  }

  .ins-details {
    padding: 5px;
  }

  .user-roles-select-div {
    min-width: 75%;
    select {
      width: 100%;
    }
  }

  .selected-user-role-panel {
    margin-top: 15px;

    .panel-heading {
      font-weight: bold;
      font-size: 1em;
    }
  }

  .guru-selected-role-list {
    li {
      padding: 5px;
    }
  }

  .user-delete-link {
    margin: 15px 0px 2px;
    font-size: 0.8em;
    color: indianred;
    font-weight: bold;
    text-decoration: underline;
  }
</style>
