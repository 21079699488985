<template>
  <div class="ide-menu-box margin-top-20px" v-if="showConfirmation">
    <div class="box is-marginless">
      <div class="has-text-centered confirm-text has-text-weight-semibold">{{confirmationText}}</div>
      <div class="columns is-mobile margin-top-10px">
        <div class="column is-6 has-text-right">
          <button class="button is-small is-rounded has-text-weight-bold is-info" @click="$emit('action')">{{yesText}}</button>
        </div>
        <div class="column is-6 has-text-left">
          <button class="button is-small is-rounded has-text-weight-semibold " @click="$emit('close')">{{noText}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationBox',
  props: ['showConfirmation', 'confirmationText', 'yesText', 'noText']
}
</script>

<style scoped lang="scss">

</style>
